import { PublicClientApplication } from "@azure/msal-browser";


const hostname = window.location.hostname;
const port = window.location.port ? `:${window.location.port}` : '';
const clientId = process.env.REACT_APP_AUTH_SERVICE_CLIENT_ID || "ef8508f0-0146-4df5-a35b-815348506072";
const tenantName = process.env.REACT_APP_AUTH_SERVICE_TENANT_NAME || "aicfm";
const b2cPolicy = process.env.REACT_APP_AUTH_SERVICE_B2C_POLICY || "B2C_1_dev_signup_signin";
const redirectUri = process.env.REACT_APP_REDIRECT_URI || "https://cfm-devint.calavista.ai";

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${b2cPolicy}`,
        redirectUri: redirectUri || `${window.location.protocol}//${hostname}${port}/`,
        postLogoutRedirectUri: redirectUri || `${window.location.protocol}//${hostname}${port}/`,
        knownAuthorities: [`${tenantName}.b2clogin.com`],
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
};

export const loginRequest = {
    scopes: ["openid", "profile", "email", 'offline_access', clientId],
};

export const msalInstance = new PublicClientApplication(msalConfig);


//scopes: [process.env.REACT_APP_AUTH_SERVICE_CLIENT_ID + "/.default"],
