import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Chat from './Components/Chat';
import ChatInput from './Components/ChatInput';
import Sidebar from './Components/Sidebar';
import FileProvider from './FileProvider';
import Footer from './Components/Footer';
import ChatMode from './Components/ChatMode';
import Modal from './Components/Modal';
import Login from './Components/Login';
import RequiredFilesModal from './Components/RequiredFilesModal';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { msalInstance, loginRequest } from "./Components/AuthConfig";
import axios from 'axios';

function App() {
    const initialMessage = { sender: 'AI-CFM', text: 'Hi! How can I help you?', id: Date.now() };
    const [chats, setChats] = useState([{ id: 'Workstream', messages: [initialMessage] },{ id: 'epics', messages: [initialMessage] },{ id: 'Unknown', messages: [initialMessage] }]);
    const [activeChatId, setActiveChatId] = useState('Workstream');
    const [isLoading, setIsLoading] = useState(false);
    const [iaRole, setIARole] = useState('default');
    const [showModal, setShowModal] = useState(false);
    const [showRequiredFilesModal, setShowRequiredFilesModal] = useState(false);
    const [userName, setUserName] = useState('User');
    const { instance, accounts } = useMsal();
    const [allRequiredFilesPresent, setAllRequiredFilesPresent] = useState(false);
    const modalRef = useRef(null);

    // set constants to initialize during build time
    const hostname = window.location.hostname;
    const port = window.location.port ? `:${window.location.port}` : '';
    const clientId = process.env.REACT_APP_AUTH_SERVICE_CLIENT_ID || "ef8508f0-0146-4df5-a35b-815348506072";
    const tenantName = process.env.REACT_APP_AUTH_SERVICE_TENANT_NAME || "aicfm";
    const b2cPolicy = process.env.REACT_APP_AUTH_SERVICE_B2C_POLICY || "B2C_1_aicfm_dev_signup_signin";
    const url = process.env.REACT_APP_REDIRECT_URI;
    const baseUrl = url || `${window.location.protocol}//${hostname}${port}`;
    const baseApiUrl = process.env.REACT_APP_AUTH_SERVICE_API_BASE_URL  || `${baseUrl}/api/v1/`;
    const logoutUrl = baseUrl || `${window.location.protocol}//${hostname}${port}/`;

    const handleTriggerloadFileRequirements = async () => {
        if (modalRef.current) {
          await modalRef.current.loadFileRequirements();
        }
      };

    useEffect(() => {
        if (accounts.length > 0) {
            const account = accounts[0];
            const tokenRequest = {
                ...loginRequest,
                account: account
            };
            instance.acquireTokenSilent(tokenRequest).then(tokenResponse => {
                const token = tokenResponse.accessToken;
                localStorage.setItem('token', token);
                console.log("TOKEN", token);
                axios.post(baseApiUrl + 'oauth2/auth', {}, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => {
                    if (response.data.redirect) {
                        window.location.href = response.data.redirect;
                    } else {
                        // Valid token
                        setUserName(response.data['user']);
                        localStorage.setItem('userName', response.data['user']);
                        localStorage.setItem('userID', response.data['user_id']);
                        console.log(response.data);
                        
                        console.log("TOKEN");
                        console.log(token);

                        axios.get(baseApiUrl + 'users/me', {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }).then(userResponse => {
                            console.log(userResponse.data);
                            setUserName(userResponse.data.user);
                            localStorage.setItem('userName', response.data['user']);
                            localStorage.setItem('userID', response.data['user_id']);
                        }).catch(error => {
                            console.error('Error obteniendo la información del usuario:', error);
                        });
                    }
                }).catch(error => {
                    console.error(error);
                });
            }).catch(error => {
                // Handle token acquisition errors (e.g., if token has expired)
                if (error.errorMessage.includes("interaction_required")) {
                    instance.acquireTokenPopup(tokenRequest).then(tokenResponse => {
                        const token = tokenResponse.accessToken;
                        axios.post(baseApiUrl + 'oauth2/auth', {}, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }).then(response => {
                            if (response.data.redirect) {
                                window.location.href = response.data.redirect;
                            } else {
                                // Valid token
                                setUserName(response.data['user']);
                                localStorage.setItem('userName', response.data['user']);                                
    
                                axios.get(baseApiUrl + 'users/me', {
                                    headers: {
                                        Authorization: `Bearer ${token}`
                                    }
                                }).then(userResponse => {
                                    console.log(userResponse.data);
                                    setUserName(userResponse.data.user);
                                }).catch(error => {
                                    console.error('Error getting the user information:', error);
                                });
                            }
                        }).catch(error => {
                            console.error(error);
                        });
                    });
                }
            });
        }
    }, [accounts, instance]);
    
    const addMessage = (newMessage) => {
        setChats(prevChats => {
            return prevChats.map(chat => {
                if (chat.id === activeChatId) {
                    return { ...chat, messages: [...chat.messages, newMessage] };
                }
                return chat;
            });
        });
    };

    const addNewChat = (newChatId) => {        
        setChats([...chats, { id: newChatId, messages: [initialMessage] }]);
        setActiveChatId(newChatId);
    };

    const switchChat = (chatId) => {
        setActiveChatId(chatId);
    };

    const activeChat = chats.find(chat => chat.id === activeChatId) || { messages: [] };

    const changeIARole = (role) => {
        setIARole(role);
        switchChat(role)
        console.log(role);
    };

    const handleSettingsClick = () => {
        setShowModal(true);
    };

    const handleLogin = () => {
        console.log("Client ID:", clientId);
        console.log("Tenant Name:", tenantName);
        console.log("B2C Policy:", b2cPolicy);
        console.log("URL:", url);
        console.log("Base URL:", baseUrl);
        console.log("Base API URL:", baseApiUrl);
        instance.loginPopup(loginRequest).catch(e => {
            console.error(e);
        });
    };

    const handleLogout = () => {
        if (accounts.length > 0) {
            const account = accounts[0];
            instance.logoutRedirect({
                account: account,
                postLogoutRedirectUri: logoutUrl
            }).catch(e => {
                console.error(e);
            });
            localStorage.removeItem('userName')
        }
    };

    const handleResultsUpdate = (filesPresent) => {
        setAllRequiredFilesPresent(filesPresent);
      };
    

    return (
        <MsalProvider instance={msalInstance}>
            <AuthenticatedTemplate>
                <FileProvider>
                    <div className="app-container">
                        <Sidebar setShowRequiredFilesModal={setShowRequiredFilesModal} />
                        <div className="chat-container">
                            <ChatMode changeIARole={changeIARole} handleSettingsClick={handleSettingsClick} isLoading = {isLoading}/>
                            <Chat addMessage={addMessage} setIsLoading={setIsLoading} messages={activeChat.messages} isLoading={isLoading} iaRole={iaRole} userName={userName} />
                            <ChatInput addMessage={addMessage} setIsLoading={setIsLoading} isLoading={isLoading} iaRole={iaRole} userName={userName} allRequiredFilesPresent={allRequiredFilesPresent} handleTriggerloadFileRequirements={handleTriggerloadFileRequirements}/>
                        </div>
                    </div>
                    <Footer />
                    <Modal 
                            showModal={showModal} 
                            setShowModal={setShowModal}
                            handleLogout={handleLogout}
                            baseApiUrl={baseApiUrl}
                        />
                    <RequiredFilesModal
                        ref={modalRef}
                        showModal={showRequiredFilesModal}
                        setShowModal={setShowRequiredFilesModal}
                        onResultsUpdate={handleResultsUpdate}
                    />
                </FileProvider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                    <Login handleLogin = {handleLogin} baseApiUrl={baseApiUrl} setUserName={setUserName} />
            </UnauthenticatedTemplate>
        </MsalProvider>
    );
}

export default App;
